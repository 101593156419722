import { rem } from "polished";
import { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import { DoctorInfoResponse } from "~//interfaces";
import {
  BaseToggle,
  Div,
  Header,
  Heading,
  Input,
  MoneyInput,
} from "~/components";

export const DiagnosisItemInputBox: FC<
  {
    index: number;
    name: string;
    handleItemChange?: (index: number) => void;
    handleDelete: (index: number) => void;
    handlePriceTypeToggle?: (index: number) => void;
  } & ComponentProps<typeof Input>
> = ({ css, name, index, handleDelete, handlePriceTypeToggle, ...props }) => {
  const { register, control, setValue, getValues } =
    useFormContext<DoctorInfoResponse>();
  const {
    field: { ref: toggleRef, ...toggleProps },
  } = useController({
    name: `estimatedItem.${index}.isVariable`,
    control,
  });

  const handleDel = () => {
    handleDelete(index);
  };

  const showPrice = !getValues(`estimatedItem.${index}.isVariable`);

  const handleToggle = () => {
    const prev = getValues(`estimatedItem.${index}.isVariable`);
    if (prev === true) {
      setValue(`estimatedItem.${index}.price`, undefined);
    }
    setValue(`estimatedItem.${index}.isVariable`, !prev);
  };

  return (
    <Div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: rem(10),
        padding: `${rem(17)} ${rem(20)} ${rem(20)}`,
        backgroundColor: "$gray08",
        borderRadius: rem(20),
        ...css,
      }}
      {...props}
    >
      <Header
        css={{
          display: "flex",
          justifyContent: "space-between",
          height: rem(30),
        }}
      >
        <Heading as="h4">진료비 {index + 1}</Heading>
        <Div
          role="button"
          css={{ cursor: "pointer", color: "$primary" }}
          onClick={handleDel}
        >
          삭제
        </Div>
      </Header>
      <Input type="text" {...register(`estimatedItem.${index}.name`)} placeholder="추가진료항목" style={{textAlign: "right"}}/>
      {showPrice ? (
        <MoneyInput {...register(`estimatedItem.${index}.price`)} />
      ) : null}
      <Div css={{ display: "flex", alignItems: "center" }}>
        <BaseToggle
          css={{
            display: "flex",
            svg: { width: rem(34), height: rem(34) },
            marginRight: rem(10),
          }}
          onClick={handleToggle}
          pressed={getValues(`estimatedItem.${index}.isVariable`) || false}
        />{" "}
        처방 기간에 따라 변동
      </Div>
    </Div>
  );
};
