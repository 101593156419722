import { rem } from "polished";
import { Dl } from "~/components";
import { styled } from "~/stitches.config";

export const SettingsSectionDlGrid = styled(Dl, {
  display: "grid",
  gridTemplateColumns: `${rem(30)} ${rem(110)} ${rem(24)} 1fr ${rem(30)}`,
  gridAutoRows: "max-content",
  padding: `${rem(10)} 0`,
  fontSize: rem(15),

  "& > dt": {
    gridColumn: `2/3`,
    color: "$gray04",
    margin: 0,
    paddingTop: "2em",
  },
  "& > dd": {
    gridColumn: "4/5",
    margin: 0,
    padding: `${rem(20)} 0`,
    "&:not(:last-of-type)": {
      borderBottom: "1px dashed $gray06",
    },
  },
  variants: {
    section: {
      diagnosisIdle: {
        "& > dt": {
          paddingTop: rem(20),
        },
      },
      diagnosisEdit: {
        "& > dt": {
          paddingTop: "2em",
        },
      },
    },
  },
});
