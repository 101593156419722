import { rem } from "polished";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SettingsSection,
  SettingsSectionContent,
  SettingsSectionHeader,
  SettingsEditButton,
  SettingsDiagnosisInfo,
  SettingsDiagnosisInfoForm,
} from "~/components";
import { useGetDoctorInfoQuery, useGetReimbursementListQuery, useGetOpeningTimesQuery } from "~/services/doctor";
import {
  selectDiagnosisEditState,
  setDiagnosisEditState,
  selectTutorialOpeningState
} from "~/store/settingsSlice";
import { api } from "~/woozooapi"

export const DiagnosisInfoSection: FC = () => {
  const dispatch = useDispatch();
  const edit = useSelector(selectDiagnosisEditState) === "edit";
  const { data } = useGetDoctorInfoQuery();
  const { data: openingTimesResponse } = useGetOpeningTimesQuery();

  return (
    <SettingsSection className="DiagnosisInfoSection">
      <SettingsSectionHeader
        headerText={edit ? "진료 정보 수정" : "진료 정보"}
        css={{ height: rem(40) }}
      >
        {!edit && (
          <SettingsEditButton
            onClick={() => {
              dispatch(setDiagnosisEditState("edit"));
            }}
          />
        )}
      </SettingsSectionHeader>
      <SettingsSectionContent
        css={{
          backgroundColor: "$white",
          border: "1px solid $gray08",
        }}
      >
        {edit ? (
          <SettingsDiagnosisInfoForm opentime={openingTimesResponse} />
        ) : (
          <SettingsDiagnosisInfo data={data} />
        )}
      </SettingsSectionContent>
    </SettingsSection>
  );
};
