export const NewIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13"
      cy="13"
      r="9"
      fill="#EB541E"
      stroke="#F7F7F7"
      strokeWidth="8"
    />
  </svg>
);
