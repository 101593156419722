import { useRouter } from "next/router";
import { rem } from "polished";
import { MouseEventHandler, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DevTool } from "@hookform/devtools";
import {
  Div,
  HospitalImageUploadList,
  Input,
  Label,
  FormRowItemWrapper,
  RegisterFormSection,
  RoundedButton,
  Form,
} from "~/components";
import {
  useGetDoctorInfoQueryState,
  useUpdateDoctorInfoMutation,
} from "~/services/doctor";
import { useFileUploadMutation } from "~/services/fileUpload";
import {
  setHospitalEditState,
  addHospitalImagesForUpdate,
  selectHospitalDataForUpdate,
} from "~/store/settingsSlice";

export const SettingsHospitalInfoForm = () => {
  const dispatch = useDispatch();
  const { data } = useGetDoctorInfoQueryState();
  const [requestUpdateDoctorInfo] = useUpdateDoctorInfoMutation();
  const [requestFileUpload] = useFileUploadMutation();
  const { register, setValue, getValues, watch, control, handleSubmit } =
    useForm({
      mode: "onChange",
      defaultValues: {
        hospitalId: data?.hospital.id,
        hospitalName: data?.hospital.name,
        hospitalPhone: data?.hospital.phone,
        address1: data?.hospital.address1,
        address2: data?.hospital.address2,
        hospitalImageUrl: data?.hospital.image ?? [],
      },
    });
  const router = useRouter();

  const hospitalImages = watch("hospitalImageUrl");

  const hospitalDataForUpdate = useSelector(selectHospitalDataForUpdate);

  useEffect(() => {
    // skip update when hospital data is not changed
    if (Object.keys(hospitalDataForUpdate).length < 1) return;

    setValue("hospitalId", hospitalDataForUpdate.id);
    setValue("hospitalName", hospitalDataForUpdate.name);
    setValue("hospitalPhone", hospitalDataForUpdate.phone);
    setValue("address1", hospitalDataForUpdate.address1);
    setValue("address2", hospitalDataForUpdate.address2);
  }, [hospitalDataForUpdate, setValue]);

  const handleHospitalRegisterClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    router.push("/settings/edit/hospital/init", undefined, {
      scroll: false,
    });
  };

  const handleHospitalImageUpload = async (files: File[]) => {
    try {
      const result = await Promise.all(
        files?.map((file) =>
          requestFileUpload({
            file,
            prefix: "hospital",
            name: getValues("hospitalName"),
          }).unwrap()
        )
      );
      console.log("result", result)
      const prevImages = getValues("hospitalImageUrl");
      const uploadedImageUrls = result
        ?.map((res: any) => res.url ?? "")
        .filter(Boolean);

      setValue("hospitalImageUrl", [...prevImages, ...uploadedImageUrls]);

      dispatch(addHospitalImagesForUpdate(uploadedImageUrls));
    } catch {
      alert("이미지 크기(용량)를 2MB 이하로 올려주세요.")
    }
  };

  const handleImageDelete = (index: number) => {
    const prev = getValues("hospitalImageUrl");
    if (prev) {
      prev.splice(index, 1);
      setValue("hospitalImageUrl", prev);
    }
  };

  const handleCancel = () => {
    dispatch(setHospitalEditState("idle"));
    document.querySelector(".HospitalInfoSection")?.scrollIntoView();
  };

  const handleSave = async () => {
    const updateData = getValues("hospitalId")
      ? {
        hospitalId: getValues("hospitalId"),
        hospitalImageUrls: getValues("hospitalImageUrl"),
      }
      : {
        hospitalName: getValues("hospitalName"),
        hospitalPhone: getValues("hospitalPhone"),
        address1: getValues("address1"),
        address2: getValues("address2"),
        hospitalImageUrls: getValues("hospitalImageUrl"),
      };
    try {
      const result = await requestUpdateDoctorInfo(updateData).unwrap();

      if (result.isSuccess) {
        dispatch(setHospitalEditState("idle"));
        router.push("/settings", undefined, { scroll: false });
      }
    } catch (e) {
      console.error(e);
    }

    document.querySelector(".HospitalInfoSection")?.scrollIntoView();
  };

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <Input type="hidden" {...register("hospitalId")} />
      <RegisterFormSection
        css={{
          "input[disabled]": {
            border: "1px solid $gray06",
            backgroundColor: "$gray08",
          },
          label: {
            fontSize: rem(15),
            color: "$gray04",
          },
          padding: 0,
        }}
      >
        <FormRowItemWrapper>
          <Label htmlFor="register-hospital">병원 등록</Label>
          <RoundedButton
            color="orange"
            outlined
            css={{ height: rem(50) }}
            onClick={handleHospitalRegisterClick}
          >
            병원 등록
          </RoundedButton>
        </FormRowItemWrapper>
        <FormRowItemWrapper>
          <Label htmlFor="hospitalName">병원 이름</Label>
          <Input
            id="hospitalName"
            type="text"
            disabled
            {...register("hospitalName")}
          />
        </FormRowItemWrapper>
        <FormRowItemWrapper>
          <Label htmlFor="hospitalPhone">전화번호</Label>
          <Input
            id="hospitalPhone"
            type="tel"
            disabled
            {...register("hospitalPhone")}
          />
        </FormRowItemWrapper>
        <FormRowItemWrapper
          css={{
            alignItems: "flex-start",
          }}
        >
          <Label htmlFor="hospitalAddress1">주소</Label>
          <Div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: rem(10),
              input: { width: "100%" },
            }}
          >
            <Input disabled {...register("address1")} />
            <Input {...register("address2")} />
          </Div>
        </FormRowItemWrapper>
        <FormRowItemWrapper>
          <Label htmlFor="hospitalImageUrl">병원 사진<br /><div style={{ color: "orangered", fontSize: rem(11) }}>{"이미지는 2MB 크기(용량) 이하로 올려주세요."}</div></Label>
          <Controller
            control={control}
            name="hospitalImageUrl"
            render={() => (
              <HospitalImageUploadList
                handleFiles={handleHospitalImageUpload}
                onDeleteClick={handleImageDelete}
                images={hospitalImages?.map((url: string, index: number) => ({
                  src: url,
                  alt: `hospital-image-${index}`,
                }))}
              />
            )}
          />
        </FormRowItemWrapper>
        <Div
          css={{
            paddingTop: rem(10),
            gridColumn: "1/5",
            display: "flex",
            justifyContent: "flex-end",
            button: {
              fontSize: rem(15),
              height: rem(50),
              width: rem(166),
              "&:last-of-type": {
                marginLeft: rem(8),
              },
            },
          }}
        >
          <RoundedButton color="black" onClick={handleCancel}>
            취소
          </RoundedButton>
          <RoundedButton type="submit" color="orange">
            저장
          </RoundedButton>
        </Div>
      </RegisterFormSection>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </Form>
  );
};
