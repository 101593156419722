import { rem } from "polished";
import { useEffect, useState } from "react";
import {
  useFieldArray,
  useForm,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import { useDispatch } from "react-redux";
import { Estimateditem } from "~//interfaces";
import { useRouter } from "next/router";

import {
  CheckboxGroup,
  Dd,
  Dl,
  Dt,
  Div,
  TextArea,
  EstimatedPaymentAddButton,
  MoneyInput,
  DiagnosisItemInputBox,
  RoundedButton,
  SettingsSectionDlGrid,
  Form,
} from "~/components";
import {
  useGetDoctorInfoQueryState,
  useUpdateDoctorInfoMutation,
  useGetSpecialListQuery,
  useGetReimbursementListQuery,
  useGetOpeningTimesQuery
} from "~/services/doctor";
import { setDiagnosisEditState, setTutorialPriceDataUpdate, selectDiagnosisEditState } from "~/store/settingsSlice";
import { getCleanedDiagnosisUpdatePayload } from "~/utils/settings.utils";
import { api } from "~/woozooapi";

export interface SettingsDiagnosisInfoForm {
  description: string;
  estimatedPayment: number;
  estimatedItem: Estimateditem[];
  treatmentSubjectIds: number[];
  reimbursementItemIds: number[];
  nonReimbursementItemIds: number[];
}
export interface SettingAdminProps {
  opentime?: any
  admin?: boolean
}

export function SettingsDiagnosisInfoForm(props: SettingAdminProps) {
  const dispatch = useDispatch();
  const { data: doctorInfo } = useGetDoctorInfoQueryState();
  const [requestUpdate] = useUpdateDoctorInfoMutation();
  const { data: reimbursementListData } = useGetReimbursementListQuery();
  const openingTimesResponse = useGetOpeningTimesQuery();
  const [textValue, setTextValue] = useState("");
  const router = useRouter();
  const [open, setOpen] = useState<boolean>()


  const replacePriceValue = Number(textValue?.replace(/\,/g, ""));
  const reimbursementItemsOptions = props.opentime && reimbursementListData && reimbursementListData?.data?.map(
    (item) => ({
      value: item.id,
      label: item.name,
      checked: false,
    })
  );

  const doctorSubjectIds = doctorInfo?.treatmentSubject?.map((item) => item.id);
  const reimbursmentIds = doctorInfo?.reimbursementItem?.map((item) => item.id);
  const formMethods = useForm<SettingsDiagnosisInfoForm>({
    mode: "onChange",
    defaultValues: {
      description: doctorInfo?.hospitalUser.description,
      estimatedPayment: doctorInfo?.hospitalUser.estimatedPayment,
      estimatedItem: doctorInfo?.estimatedItem,
      treatmentSubjectIds: doctorSubjectIds,
      reimbursementItemIds: doctorInfo?.reimbursementItem?.map(
        (item) => item.id
      ),
      nonReimbursementItemIds: doctorInfo?.nonReimbursementItem?.map(
        (item) => item.id
      ),
    },
  });
  const { control, register, setValue, getValues, handleSubmit, trigger } =
    formMethods;
  const { data: subjectList } = useGetSpecialListQuery();
  const {
    fields: estimatedItemFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "estimatedItem",
  });

  const onSubmit: SubmitHandler<SettingsDiagnosisInfoForm> = async (data) => {
    const cleanedData = getCleanedDiagnosisUpdatePayload(data);
    console.log("cleaned", cleanedData)
    if (cleanedData.reimbursementItemIds.length === 0 && cleanedData.treatmentSubjectIds.length === 0) {
      alert("진료과목을 선택해 주세요.")
    } else {
      try {
        const result = await requestUpdate(cleanedData).unwrap();
        if (result.isSuccess) {
          dispatch(setDiagnosisEditState("idle"));
        }

        document.querySelector(".DiagnosisInfoSection")?.scrollIntoView();
      } catch (e: any) {
        alert("비급여 진료비를 입력해 주세요.");
      }
      api.doctor.info().then((res) => { dispatch(setTutorialPriceDataUpdate(res.hospitalUser.estimatedPayment)) })
    }
  };

  const onlyNumberText1 = (data: string) => {
    setTextValue(data)
    if (data) {
      const replaceData = data.replace(/[^0-9]/g, "")
      const removedCommaValue: number = Number(replaceData.replaceAll(",", ""))
      return setTextValue(removedCommaValue.toLocaleString())
    }
  };
  const handleRouteChange = () => setOpen(true)
  const handleRouteChange2 = () => setOpen(false)

  useEffect(() => {
    if (!props.opentime) {
      openingTimesResponse
    }
  })
  useEffect(() => {
    api.doctor.info().then((res) => { setTextValue(res.hospitalUser.estimatedPayment?.toLocaleString()) })
  }, [])


  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SettingsSectionDlGrid section="diagnosisEdit">
          <Dt>진료 소개</Dt>
          <Dd>
            <TextArea
              {...register("description", {})}
              css={{
                height: rem(300),
                width: "100%",
                border: "1px solid $gray06",
                borderRadius: rem(20),
                padding: `${rem(16)} ${rem(30)} ${rem(20)}`,
              }}
            />
          </Dd>
          <Dt>비급여 진료비</Dt>
          <Dd>
            <Dl
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                dt: { gridColumn: "1/2" },
                dd: {
                  gridColumn: "2/3",
                  textAlign: "right",
                },
                rowGap: rem(8),
              }}
            >
              <Dt
                css={{
                  gridColumn: "1/3",
                  fontWeight: "bold",
                  placeSelf: "center left",
                }}
              >
                기본 진료비
              </Dt>
              <Dd
                css={{
                  gridColumn: "1/3",
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: rem(14),
                  alignItems: "center",
                  data: {
                    fontSize: rem(15),
                    color: "$primary",
                    fontWeight: "bold",
                    marginRight: rem(4),
                  },
                }}
              >
                <MoneyInput
                  value={textValue}
                  css={{
                    input: { fontSize: rem(17) },
                    span: { fontSize: rem(14) },
                  }}
                  min={0}
                  {...register("estimatedPayment", {
                    required: true, onChange: (e) => {
                      onlyNumberText1(e.target.value)
                    }
                  })}
                />
              </Dd>
              <Div
                css={{
                  gridColumn: "1/3",
                  display: "grid",
                  rowGap: rem(10),
                  paddingTop: rem(10),
                }}
              >
                {estimatedItemFields?.map((field, index) => (
                  <DiagnosisItemInputBox
                    name="estimatedItem"
                    key={field.id}
                    css={{ gridColumn: "1/3" }}
                    handleDelete={(index) => remove(index)}
                    index={index}
                  />
                ))}
                <EstimatedPaymentAddButton
                  css={{ marginTop: rem(10) }}
                  onClick={() => {
                    append({
                      name: "",
                    });
                  }}
                />
              </Div>
            </Dl>
          </Dd>
          {
            props.admin ? null
              : <>
                <Dt>진료 과목</Dt>
                <Dd>
                  <CheckboxGroup
                    css={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: rem(14),
                      rowGap: rem(10),
                    }}
                    handleCheck={(selectedIds) => {
                      setValue(
                        "treatmentSubjectIds",
                        selectedIds.map((id) => Number(id))
                      );
                    }}
                    items={(subjectList?.data ?? [])?.map((subject) => {
                      return doctorSubjectIds?.includes(subject.id)
                        ? {
                          label: subject.name,
                          value: String(subject.id),
                          checked: true,
                        }
                        : {
                          label: subject.name,
                          value: String(subject.id),
                          checked: false,
                        };
                    })}
                  />
                </Dd>
                <Dt>증상 항목<p style={{ color: "orangered", fontSize: "11px" }}>(앱에서 일반 진료 신청 시 환자들에게 노출되는 증상 메뉴입니다.)</p></Dt>
                <Dd>
                  <CheckboxGroup
                    css={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: rem(14),
                      rowGap: rem(10),
                    }}
                    handleCheck={(selectedIds) => {
                      setValue(
                        "reimbursementItemIds",
                        selectedIds.map((id) => Number(id))
                      );
                    }}
                    items={(reimbursementItemsOptions ?? [])?.map((subject: { value: number; }) => {
                      return reimbursmentIds?.includes(subject.value)
                        ? {
                          ...subject,
                          value: String(subject.value),
                          checked: true,
                        }
                        : {
                          ...subject,
                          value: String(subject.value),
                          checked: false,
                        };
                    })}
                  />
                </Dd>
              </>
          }
          <Div
            css={{
              paddingTop: rem(10),
              paddingBottom: rem(20),
              gridColumn: "1/5",
              display: "flex",
              justifyContent: "flex-end",
              button: {
                fontSize: rem(15),
                height: rem(50),
                width: rem(166),
                "&:last-of-type": {
                  marginLeft: rem(8),
                },
              },
            }}
          >
            <RoundedButton
              color="black"
              onClick={() => {
                dispatch(setDiagnosisEditState("idle"));
                document
                  .querySelector(".DiagnosisInfoSection")
                  ?.scrollIntoView();
              }}
            >
              취소
            </RoundedButton>
            <RoundedButton type="submit" color="orange" onClick={() => setValue("estimatedPayment", replacePriceValue)}>
              저장
            </RoundedButton>
          </Div>
        </SettingsSectionDlGrid>
      </Form>
    </FormProvider>
  );
};
