import React, { useEffect, useState } from 'react';
import { rem } from "polished";
import {
    BaseDialog2,
    Heading,
    Line,
    Div,
    Span
} from "~/components";
import Image from "next/image";
import StepChip from "../Chip/StepChip";
import { CheckBox } from "@mui/icons-material";

interface Iprops {
    show2: boolean;
    close: () => void;
}

function SettingPopup(props: Iprops) {
    const [show, setShow] = useState(props.show2);
    useEffect(() => {
        setShow(props.show2);
    }, [props.show2])

    return (
        <>
            <BaseDialog2
                showDialog={show}
                close={props.close}>
                <Heading css={{
                    fontSize: rem(17),
                    fontWeight: 'bold',
                    color: '#333',
                    lineHeight: 1.4,
                    height: 24,
                    textAlign: 'center',
                    marginBottom: 26,
                }}>
                    비급여 진료비용을 입력해주세요
                </Heading>
                <Div css={{
                    display: 'block'
                }}>
                    <StepChip>STEP 1</StepChip>
                    <StepChip>STEP 2</StepChip>
                    <StepChip>STEP 3</StepChip>
                </Div>

                <div style={{
                    display: 'flex',
                    marginBottom: '26px',
                    marginTop: '14.5px'
                }}>
                    <Image src={'/Group 650@3x.png'} width={228} height={216}></Image>
                    <span style={{
                        minWidth: 143,
                        padding: `${rem(160)} 0 0 10px`,
                        fontSize: 12
                    }}>진료비 입력 후 저장 시 <br />앱 내 진료 신청화면에서 <br />좌측 화면처럼 보여집니다.</span>
                </div>
                <Line style={{
                    width: "100%"
                }}></Line>
                <Span css={{
                    color: "#333",
                    fontSize: '12px',
                    lineHeight: 1.4,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    오늘 하루 그만보기
                    <CheckBox
                        style={{
                            cursor: "pointer",
                            fontSize: '18px'
                        }}
                        onClick={() => props.close()}
                    />
                </Span>
            </BaseDialog2>
        </>
    );
}

export default SettingPopup;

export async function getStaticProps() {
    return {
        props: {}
    };
}