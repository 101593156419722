import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { rem } from "polished";
import {
  Heading,
  Span,
  Div,
  Dropdown,
  DropdownOnChange,
  StationButton,
  WaitingButton,
} from "~/components";
import { useGetDiagnosisListQuery } from "~/services/diagnosis";
import { styled } from "~/stitches.config";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useDispatch, useSelector } from "react-redux";
import chetstate from "../../public/icon_cs@3x.png";
import { selectNotificataionInfo } from "~/store/notificationSlice";
import CallIcon from '@mui/icons-material/Call';

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  ".page-name": {
    height: rem(35),
    fontSize: "$s3-20",
    fontWeight: "bold",
    color: "$blacks",
  },
  ".sorted-btn button": {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "$p5-13",
    color: "&gray01",
    fontWeight: "normal",
    padding: 0,
    "&:hover, &:focus": {
      fontWeight: "bold",
    },
  },
});
interface Props {
  lastestSortedHandler: () => void;
  pastSortedHandler: () => void;
  onChange: DropdownOnChange;
}

const DIAGNOSIS_STATUS_OPTIONS_DOCTOR = [
  { label: "전체", value: "all" },
  { label: "대기", value: "waiting" },
  { label: "진료중", value: "ongoing" },
  { label: "취소", value: "canceled" },
];

export const SortAndFilter: React.FC<Props> = ({ ...props }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isStation = router.pathname.includes("/station");
  const chatData = useSelector(selectNotificataionInfo);

  const { data: waitingListInfo } = useGetDiagnosisListQuery(
    {},
    {
      pollingInterval: 10000,
    }
  );


  return (
    <Flex css={{ flexDirection: "column", marginTop: "19px" }}>
      <Flex
        css={{
          justifyContent: "space-between",
          marginTop: "19px",
          width: "100%",
        }}
      >
        <Flex css={{ gap: rem(7), height: rem(50) }}>
          <Heading className="page-name">
            {isStation ? "처방전 수납대기" : "대기실"}{" "}
          </Heading>
          {!isStation ? (
            <>
              <Flex css={{ alignItems: "end" }}>
                <Span
                  css={{
                    fontSize: "$s5-18",
                    fontWeight: "bold",
                    color: "$primary",
                    lineHeight: "1.4",
                  }}
                >
                  {waitingListInfo?.totalWaiting ?? 0}
                </Span>
                <Span
                  css={{
                    color: "$gray04",
                    marginLeft: rem(4),
                  }}
                >
                  / {waitingListInfo?.count}건
                </Span>
              </Flex>
            </>
          ) : null}
        </Flex>
        <Flex css={{ gap: rem(8) }}>
          {!isStation && (
            <>
              {/* <WaitingButton /> // 헤더 부분 스테이션, 진료실 버튼
              <StationButton /> */}
              <Div
                css={{
                  ".react-select__control": {
                    fontSize: rem(14),
                    minHeight: rem(44),
                    backgroundColor: "$white",
                  },
                  ".react-select__option": {
                    fontSize: rem(14),
                    paddingLeft: rem(24),
                  },
                }}
              >
                <Dropdown
                  id="selectDiagnosisStatus"
                  aria-label="진료 상태 선택"
                  onChange={props.onChange}
                  options={DIAGNOSIS_STATUS_OPTIONS_DOCTOR}
                  defaultValue={{ label: "전체", value: "all" }}
                />
              </Div>
            </>
          )}
        </Flex>
      </Flex>
      {
        waitingListInfo?.isAnnouncement === false ?
          chatData && chatData.results?.map((data: any, index: number) =>
          (<div style={{
            width: "100%",
            height: 34,
            margin: "10px 0 0",
            padding: "0px 1rem 0px 0px",
            borderRadius: "20px",
            backgroundColor: "#ececec",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            letterSpacing: "-0.36px",
            fontSize: 12,
            lineHeight: 1.4
          }} key={index}>
            <div style={{ display: "flex" }}>
              <div>
                {data.icon ? <div style={{ height: 34, width: 34, background: 'black', borderRadius: 34 }}>
                  <CallIcon style={{ color: "white", margin: '5px 7px 7px 5px' }} />
                  {/* <img style={{ margin: '8px 7px 7px 7.8px' }} src={data.icon} width={rem(16)} height={rem(16.8)} alt="icon" /> */}
                </div> : null}
              </div>
              <div style={{ color: "rgba(0, 0, 0, 0.6)", paddingLeft: "10px", placeSelf: 'center' }}>
                {data.title}
              </div>
            </div>
            {index === 0 ? <div style={{ color: "#000", borderBottom: "1px solid", cursor: "pointer" }}>
              <div onClick={() => window.open('http://pf.kakao.com/_xkKbfb', '_blank')}>카카오 채널톡 바로가기</div>
            </div> : null}
          </div>)
          )
          :
          chatData && chatData.results?.map((data: any, index: number) =>
          (<div style={{
            width: "100%",
            height: 34,
            margin: "10px 0 0",
            padding: "0px 1rem 0px 0px",
            borderRadius: "20px",
            backgroundColor: "#ececec",
            // backgroundColor: "red",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            letterSpacing: "-0.36px",
            fontSize: 12,
            lineHeight: 1.4
          }} key={index}>
            <div style={{ display: "flex" }}>
              {data.icon ? <div style={{ height: 34, width: 34, background: 'black', borderRadius: 34 }}>
                {/* <img style={{ margin: '8px 7px 7px 7.8px' }} src={data.icon} width="19px" height="17.8px" alt="icon" /> */}
                <CallIcon style={{ color: "white", margin: '5px 7px 7px 5px' }} />
              </div> : null}
              <div style={{ color: "rgba(0, 0, 0, 0.6)", paddingLeft: "10px", placeSelf: 'center' }}>
                {data.title}
              </div>
            </div>
            {index === 0 ?
              <div style={{ color: "#000", borderBottom: "1px solid", cursor: "pointer" }}>
                <div onClick={() => window.open('http://pf.kakao.com/_xkKbfb', '_blank')}>카카오 채널톡 바로가기</div>
              </div> : null}
          </div>)
          )
      }
    </Flex >
  );
};
