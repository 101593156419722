import { rem } from "polished";
import { NoDataIcon } from "~/components";
import { styled } from "~/stitches.config";

const Div = styled("div", {
  marginTop: rem(89),
  textAlign: "center",
  fontSize: rem(14),
  lineHeight: "1.4",
  lineSpacing: "normal",
  color: "$gray03",
  svg: {
    marginBottom: rem(20),
  },
  '[aria-label="orange-text"]': {
    color: "$primary",
  },
});
export const NoPastHistory = () => {
  return (
    <Div>
      <NoDataIcon />
      <div>
        <span>지난 진료 내역이 존재하지 않습니다.</span> <br />
        <span>
          [지난 진료]에서는{" "}
          <span aria-label="orange-text">어제까지 진행한 진료 내역</span>을
          확인할 수 있습니다.
        </span>
      </div>
    </Div>
  );
};
