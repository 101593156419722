import { rem } from "polished";
import { ComponentProps, FC, VFC } from "react";
import { RoundedButton } from "~/components";

export const EditButton: FC<ComponentProps<typeof RoundedButton>> = ({
  css,
  children,
  ...props
}) => (
  <RoundedButton
    color="black"
    css={{ height: rem(40), width: rem(80), ...css }}
    {...props}
  >
    수정
  </RoundedButton>
);

export const SettingsEditButton: FC<ComponentProps<typeof RoundedButton>> = ({
  css,
  children,
  ...props
}) => (
  <RoundedButton
    color="black"
    css={{ height: rem(40), width: rem(80), ...css }}
    {...props}
  >
    {children ? children : "수정"}
  </RoundedButton>
);

export const PasswordEditButton: VFC<ComponentProps<typeof RoundedButton>> = ({
  css,
  ...props
}) => (
  <RoundedButton
    outlined
    css={{
      height: rem(50),
      border: "1px solid $gray01",
      backgroundColor: "$white",
      color: "$gray01",
      boxShadow: "0 3px 3px 0 rgba(140, 140, 140, 0.2)",
      ...css,
    }}
    {...props}
  >
    비밀번호 수정
  </RoundedButton>
);
