import { rem } from "polished";
import { styled } from "~/stitches.config";

export const MedicalList = styled("div", {
  ".ant-list-lg .ant-list-item": {
    padding: 0,
  },
  ".ant-list-split .ant-list-item": {
    border: "none",
  },
  ".ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child":
    {
      border: "none",
    },
  ".ant-list-pagination": {
    textAlign: "center",
    marginTop: rem(20),
  },
  ".ant-pagination-item": {
    border: "none",
    borderRadius: "50%",
    fontSize: "$p6-12",
    fontWeight: "normal",
    fontFamily: "NotoSansCJKKR",
    minWidth: rem(30),
    height: rem(30),
    marginRight: rem(10),
    "&:hover": {
      boxShadow: `0 ${rem(3)} ${rem(3)} 0 rgba(140, 140, 140, 0.2)`,
    },
  },
  ".ant-pagination-item a": {
    color: "$gray03",
    "&:hover": {
      color: "$gray03",
    },
  },
  ".ant-pagination-item-active a": {
    color: "$primary",
    fontWeight: "bold",
    "&:hover": {
      color: "$primary",
    },
  },
  ".ant-pagination-prev, .ant-pagination-next": {
    marginRight: rem(10),
    minWidth: rem(30),
    height: rem(30),
  },
  ".ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link":
    {
      color: "$gray03",
      border: "none",
      borderRadius: "50%",
      minWidth: rem(30),
      height: rem(30),
      "&:hover": {
        boxShadow: `0 ${rem(3)} ${rem(3)} 0 rgba(140, 140, 140, 0.2)`,
        color: "$gray03",
      },
      "&:active": {
        color: "$primary",
      },
    },
  ".ant-pagination-disabled .ant-pagination-item-link": {
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    color: "$white",
  },
  ".ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon":
    { color: "$primary" },
});
