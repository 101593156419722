import { Divider } from "antd";
import { rem } from "polished";
import { Dropdown, DropdownOnChange, Div } from "~/components";
import { styled } from "~/stitches.config";

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  '[aria-label="black-text"]': {
    fontWeight: "normal",
    color: "$blacks",
  },
  '[aria-label="total"]': {
    fontWeight: "bold",
    color: "$primary",
    lineHeight: "1.4",
  },
  '[aria-label="gray-text"]': {
    color: "$gray04",
  },
  ".sorted-btn button": {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "$p5-13",
    color: "&gray01",
    fontWeight: "normal",
    padding: 0,
    "&:hover, &:focus": {
      fontWeight: "bold",
    },
  },
});
interface Props {
  total?: number;
  lastestSortedHandler: () => void;
  pastSortedHandler: () => void;
  onChange: DropdownOnChange;
}
const DUMMY_DATA = [
  { label: "전체", value: "all" },
  { label: "대기", value: "waiting" },
  { label: "종료", value: "terminated" },
  { label: "실패", value: "canceled" },
];
export const PastSortAndFilter: React.FC<Props> = ({ ...props }) => {
  return (
    <Flex css={{ flexDirection: "column", marginTop: "19px" }}>
      <Flex
        css={{
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {props.total && props.total > 0 ? (
          <Flex css={{ gap: rem(7), fontSize: "$p2-16" }}>
            <span aria-label="black-text">총</span>
            <Flex css={{ gap: rem(2) }}>
              <div aria-label="total"> {props.total} </div>
              <span aria-label="gray-text">건</span>
            </Flex>
          </Flex>
        ) : null}

        <Flex
          css={{
            gap: rem(20),
            justifyContent: "end",
            width: "100%",
            marginTop: rem(17),
          }}
        >
          <Flex className="sorted-btn">
            <button onClick={props.lastestSortedHandler}>최신순</button>
            <Divider type="vertical" />
            <button onClick={props.pastSortedHandler}>과거순</button>
          </Flex>
          <Div
            css={{
              width: rem(152),
              ".react-select__control": {
                fontSize: rem(14),
                minHeight: rem(44),
                backgroundColor: "$white",
              },
              ".react-select__option": {
                fontSize: rem(14),
                paddingLeft: rem(24),
              },
            }}
            style={{ width: rem(152) }}
          >
            <Dropdown
              id="selectDiagnosisStatus"
              aria-label="진료 상태 선택"
              onChange={props.onChange}
              options={DUMMY_DATA}
              defaultValue={{ label: "전체", value: "all" }}
            />
          </Div>
        </Flex>
      </Flex>
    </Flex>
  );
};
