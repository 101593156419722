import { AxiosResponse } from "axios";
import { getSession } from "next-auth/react";
import useSWR from "swr";
import { axios } from "./baseAxiosInstance";
import { URLS } from "./urls";
import {
  DoctorInfoResponse,
  DoctorInfoUpdatePayload,
  DoctorInfoUpdateResponse,
  DoctorSignUpRequestPayload,
  DoctorSignUpResponse,
  DoctorSubjectListResponse,
  DoctorTutorialInfo,
  DoctorActivate,
  DoctorSignUpRequestPayload2,
  DoctorOcr,
  DoctorOperate,
} from "~/interfaces";

export const doctor = {
  signUp: async (payload: DoctorSignUpRequestPayload) => {
    //sign v1
    const sessions = await getSession();

    const { data } = await axios.request<
      DoctorSignUpResponse,
      AxiosResponse<DoctorSignUpResponse>,
      DoctorSignUpRequestPayload
    >({
      url: URLS.DOCTOR.SIGN_UP,
      method: "POST",
      data: payload,
      headers: {
        Authorization: `jwt ${sessions?.accessToken}`,
      },
    });

    return data;
  },
  // signUp: async (payload: DoctorSignUpRequestPayload2) => {
  //   //sign v2
  //   const sessions = await getSession();

  //   const { data } = await axios.request<
  //     DoctorSignUpResponse,
  //     AxiosResponse<DoctorSignUpResponse>,
  //     DoctorSignUpRequestPayload2
  //   >({
  //     url: URLS.DOCTOR.SIGN_UP_V2,
  //     method: "POST",
  //     data: payload,
  //     // headers: {
  //     //   Authorization: `jwt ${sessions?.accessToken}`,
  //     // },
  //   });

  //   return data;
  // },
  info: async () => {
    const sessions = await getSession();

    const { data } = await axios.request<
      DoctorInfoResponse,
      AxiosResponse<DoctorInfoResponse>
    >({
      url: URLS.DOCTOR.INFO,
      method: "GET",
      headers: {
        Authorization: `jwt ${sessions?.accessToken}`,
      },
    });
    return data;
  },

  update: async (payload: DoctorTutorialInfo) => {
    const sessions = await getSession();
    const { data } = await axios.request<
      DoctorInfoUpdateResponse,
      AxiosResponse<DoctorInfoUpdateResponse>,
      DoctorTutorialInfo
    >({
      url: URLS.DOCTOR.INFO,
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `jwt ${sessions?.accessToken}`,
      },
    });

    return data;
  },
  specialList: async () => {
    const { data } = await axios.request<
      DoctorSubjectListResponse,
      AxiosResponse<DoctorSubjectListResponse>
    >({
      url: URLS.DOCTOR.REIMBURSEMENT_ITEMS_LIST,
      method: "GET",
    });

    return data.data;
  },
  reimbursementList: async () => {
    const { data } = await axios.request<
      DoctorSubjectListResponse,
      AxiosResponse<DoctorSubjectListResponse>
    >({
      url: URLS.DOCTOR.REIMBURSEMENT_ITEMS_LIST,
      method: "GET",
    });
    return data.data;
  },
  activate: async (id: number, payload: DoctorActivate) => {
    const sessions = await getSession();
    const { data } = await axios.request<
      DoctorInfoUpdateResponse,
      AxiosResponse<DoctorInfoUpdateResponse>,
      DoctorActivate
    >({
      url: URLS.DOCTOR.ACTIVATE(id),
      method: "PUT",
      data: payload,
      headers: {
        Authorization: `jwt ${sessions?.accessToken}`,
      },
    });

    return data;
  },
  operate: async (payload: DoctorOperate) => {
    const sessions = await getSession();
    const { data } = await axios.request<
      DoctorInfoUpdateResponse,
      AxiosResponse<DoctorInfoUpdateResponse>,
      DoctorOperate
    >({
      url: URLS.DOCTOR.PUBLIC_STATE,
      method: "POST",
      data: payload,
      headers: {
        Authorization: `jwt ${sessions?.accessToken}`,
      },
    });
    return data;
  },
};

export const useSubjectList = () =>
  useSWR<DoctorSubjectListResponse["data"]>(
    URLS.DOCTOR.TREATMENT_SUBJECT_LIST,
    doctor.specialList,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
