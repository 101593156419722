import React, { ReactChildren } from 'react';
import styled, { css } from 'styled-components';
import rem from 'polished';
import { PopupButtons, SideBarButtons } from "~/components/Buttons";
import { SettingIcon } from '../icons/SettingIcon';

interface Iprops {
    children: any;
    label?: string;
}
interface Istyle {
    flex?: boolean;
}

const Chip = styled.div`
    width: 68px;
  height: 20px;
  flex-grow: 0;
  border-radius: 100px;
  text-align: center;
  margin: 32.1px 20px 30.9px 1px;
  font-size: 10px;
  background-color: #eb541e;
  color: white;
  padding: 3px;
`;
const Container = styled.div`
display: flex;
`;
const Span = styled.span<Istyle>`
width: 232px;
align-items: center;
${(props) =>
        props.flex &&
        css`
      display: flex;
    `};
`;

const Button = styled.div`
    width: 60px;
  height: 30.1px;
  border-radius: 100px;
  background-color: #000;
  color: white;
  padding: 3.4px 0;
  text-align: center;
  margin: 0 5px;
`;



function StepChip(props: Iprops) {
    return (
        <>
            <Container>
                <Chip>
                    {props.children}
                </Chip>
                {
                    props.children === 'STEP 1' ?
                        <Span flex>좌측
                            <PopupButtons
                                href={''} visiting={false}>
                                <SettingIcon />
                                <div>설정</div>
                            </PopupButtons>
                            메뉴 클릭
                        </Span>
                        : props.children === 'STEP 2' ?
                            <Span flex>진료 정보 <Button>수정</Button> 버튼 클릭</Span>
                            : props.children === 'STEP 3' ?
                                <Span style={{
                                    marginTop: '28px'
                                }}>
                                    탈모, 여드름, 사후피임약, 다이어트 등
                                    <strong>
                                        비급여 진료비에 대해 항목추가하여 <br />예상 진료비 입력
                                    </strong>
                                </Span>
                                : null
                }
            </Container>
        </>
    );
}

export default StepChip;

export async function getStaticProps() {
    return {
        props: {}
    };
}