import { rem } from "polished";
import { Dd, Div, Dl, Dt, Heading, HospitalPic } from "~/components";
import { useGetDoctorInfoQuery } from "~/services/doctor";

export const SettingsHospitalInfo = () => {
  const { data } = useGetDoctorInfoQuery();
  return (
    <>
      <Heading as="h3" css={{ fontSize: rem(22), fontWeight: "bold" }}>
        {data?.hospital.name}
      </Heading>
      <Div css={{ height: rem(20) }} />
      <Dl
        css={{
          display: "grid",
          gridTemplateColumns: `${rem(130)} 1fr`,
          rowGap: rem(16),
          dt: {
            color: "$gray03",
            fontSize: rem(15),
          },
          dd: {
            fontSize: rem(16),
          },
        }}
      >
        <Dt>전화번호</Dt>
        <Dd>{data?.hospital.phone}</Dd>
        <Dt>주소</Dt>
        <Dd>
          {data?.hospital.address1} {data?.hospital.address2}
        </Dd>
        <Dt css={{ gridColumn: "1/3", paddingTop: rem(4) }}>병원 사진</Dt>
        <Dd
          css={{
            gridColumn: "1/3",
            display: "flex",
            flexWrap: "wrap",
            gap: rem(10),
            paddingTop: rem(4),
          }}
        >
          {data?.hospital.image?.map((src, index) => (
            <HospitalPic key={`${src}-${index}`} src={src} />
          ))}
        </Dd>
      </Dl>
    </>
  );
};
