import { rem } from "polished";
import { ComponentProps, VFC } from "react";
import { DoctorInfoResponse } from "~//interfaces";
import { RoundedButton, SettingsEditButton } from "~/components";

export const DoctorApprovalStatusButton: VFC<
  {
    status: DoctorInfoResponse["hospitalUser"]["approvalStatus"];
  } & ComponentProps<typeof RoundedButton>
> = ({ status, ...props }) => {
  if (status === "approve" || status === "승인") {
    return <SettingsEditButton {...props} />;
  }

  return (
    <RoundedButton
      outlined
      disabled
      css={{
        backgroundColor: "$white",
        color: "$gray03",
        borderColor: "$gray06",
        height: rem(40),
        width: rem(104),
      }}
      {...props}
    >
      승인 중
    </RoundedButton>
  );
};
