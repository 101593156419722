export const RocketDoctorLogo = () => (
  <svg
    width="2.3em"
    height="1em"
    viewBox="0 0 85 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>로켓닥터 로고</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.767 26.416a10.395 10.395 0 0 1-7.322-3.034A12.607 12.607 0 0 0 62.24 15c.323-5.65 4.922-10.147 10.532-10.147 5.817 0 10.55 4.836 10.55 10.78 0 5.946-4.738 10.783-10.555 10.783zM53.264 34.174c-5.812 0-10.54-4.837-10.54-10.782 0-5.944 4.728-10.782 10.54-10.782 5.812 0 10.541 4.838 10.541 10.782 0 5.945-4.728 10.782-10.54 10.782zM32.194 25.275c-3.194 0-6.055-1.467-7.989-3.773a12.71 12.71 0 0 0-2.114-10.052c1.286-4.469 5.327-7.74 10.103-7.74 5.805 0 10.53 4.836 10.53 10.781S38 25.275 32.194 25.275zM12.223 29.725c-5.813 0-10.541-4.838-10.541-10.782 0-5.945 4.728-10.783 10.541-10.783 5.813 0 10.54 4.838 10.54 10.783 0 5.944-4.728 10.782-10.54 10.782z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.767 26.416a10.396 10.396 0 0 1-7.322-3.034A12.607 12.607 0 0 0 62.24 15c.323-5.65 4.922-10.148 10.532-10.148 5.817 0 10.55 4.837 10.55 10.782s-4.738 10.782-10.555 10.782zm-19.502 7.758c-5.812 0-10.54-4.837-10.54-10.782 0-5.944 4.728-10.782 10.54-10.782 5.811 0 10.54 4.838 10.54 10.782 0 5.945-4.728 10.782-10.54 10.782zm-21.071-8.899c-3.194 0-6.056-1.467-7.989-3.773a12.712 12.712 0 0 0-2.114-10.052c1.285-4.469 5.327-7.74 10.103-7.74 5.805 0 10.53 4.836 10.53 10.781S38 25.275 32.194 25.275zm-19.971 4.45c-5.813 0-10.541-4.838-10.541-10.782 0-5.945 4.728-10.783 10.54-10.783 5.814 0 10.541 4.838 10.541 10.783 0 5.944-4.728 10.782-10.54 10.782zm60.544-26.55c-6.052 0-11.09 4.523-12.059 10.436a11.997 11.997 0 0 0-7.491-2.632c-3.454 0-6.576 1.475-8.802 3.837 0-.102.007-.204.007-.306C44.422 7.613 38.934 2 32.19 2c-5.179 0-9.614 3.309-11.396 7.969a12.05 12.05 0 0 0-8.562-3.588C5.488 6.381 0 11.995 0 18.891s5.488 12.51 12.232 12.51c5.179 0 9.614-3.31 11.394-7.969a12.043 12.043 0 0 0 8.564 3.592c3.454 0 6.576-1.474 8.802-3.836 0 .101-.007.203-.007.305 0 6.898 5.487 12.51 12.232 12.51 6.052 0 11.09-4.521 12.06-10.435a11.994 11.994 0 0 0 7.49 2.632C79.512 28.2 85 22.586 85 15.69S79.512 3.179 72.767 3.179"
      fill="#E73E11"
    />
    <path
      d="M47.027 23.653c2.837.048 5.83-.141 8.225-.634l-.189-1.418c-2 .394-4.38.583-6.333.583v-3.699h4.915v-1.449h-6.618v6.617zm2.002 2.994h6.9v4.002h1.703v-5.451H49.03l-.001 1.449zm10.824-7.042h-2.217v-3.232h-1.703v8.162h1.703v-3.482h2.221l-.004-1.448z"
      fill="#E73E11"
    />
    <path
      d="M57.713 30.726h-1.857v-4.001h-6.9V25.12h8.757v5.606zm-1.702-.154h1.547v-5.297H49.11v1.295h6.901v4.002zm1.702-5.958h-1.857v-8.317h1.857v3.23h2.221v1.604h-2.221v3.483zm-1.702-.155h1.547v-3.483h2.222v-1.294h-2.222v-3.23H56.01v8.007zm-8.125-.72c-.283 0-.569-.002-.856-.007h-.076v-6.773h6.774v1.603H48.81v3.55c2.083-.006 4.415-.223 6.242-.582l.08-.017.21 1.571-.07.015c-2.038.416-4.634.64-7.386.64zm-.777-.161c2.996.046 5.852-.172 8.06-.615l-.17-1.268c-1.846.357-4.182.57-6.267.57h-.075v-3.857h4.917v-1.295h-6.465v6.465zM76.366 8.737v4.459h-3.04v1.449h3.04v7.878h1.703V8.737h-1.703zm-9.61 10.081c2.836.047 5.624-.09 8.15-.598l-.205-1.434a33.574 33.574 0 0 1-6.24.583V14.85h4.145v-1.448h-4.149v-2.254h4.759v-1.45h-6.46v9.12z"
      fill="#E73E11"
    />
    <path
      d="M78.146 22.602h-1.857v-7.878h-3.04v-1.605h3.04V8.66h1.857v13.942zm-1.702-.155h1.552V8.815h-1.552v4.459h-3.04v1.295h3.04v7.878zm-8.68-3.538c-.33 0-.666-.003-1.008-.008h-.076V9.62h6.61v1.604h-4.756v2.099h4.144v1.6h-4.144v2.368c2.063-.006 4.12-.2 6.148-.583l.08-.015.226 1.587-.072.014c-2.041.412-4.39.614-7.152.614zm-.93-.162c3.127.048 5.74-.142 7.983-.584l-.181-1.282a33.81 33.81 0 0 1-6.175.57h-.077v-2.678h4.144v-1.297H68.38V11.07h4.759V9.776h-6.305l.002 8.97zM18.736 24.285v-1.419H12.97v-2.175h3.845v-1.433h-7.39v-1.86h7.09v-4.71H7.707v1.433h7.09v1.843h-7.09v4.727h3.545v2.175H5.501v1.419h13.235z"
      fill="#E73E11"
    />
    <path
      d="M18.813 24.36H5.423v-1.572h5.75v-2.02H7.63v-4.88h7.09V14.2H7.63v-1.59h8.965v4.866h-7.09v1.705h7.387v1.588h-3.845v2.02h5.767v1.571zm-13.235-.153h13.08v-1.268h-5.77v-2.325h3.844v-1.28h-7.39v-2.013h7.091v-4.557h-8.65v1.28h7.09v1.997h-7.09v4.573h3.546v2.33H5.578v1.263zM37.735 7.192h-1.638v8.95h1.638v-8.95zm-3.844 8.792h-1.86v.646c0 .883-.582 1.72-1.401 2.363a7.048 7.048 0 0 1-2.932 1.371l.899 1.34a8.52 8.52 0 0 0 2.678-1.23c.756-.55 1.359-1.15 1.686-1.78.316.634.914 1.23 1.67 1.78a8.454 8.454 0 0 0 2.695 1.23l.898-1.34a7.02 7.02 0 0 1-2.946-1.37c-.82-.646-1.387-1.481-1.387-2.364v-.646zm1.072-8.555h-1.64v3.04h-1.969a8.949 8.949 0 0 0 .362-2.568h-5.342v1.466h3.56c-.056.423-.157.84-.299 1.244l-3.797.239.331 1.449 2.694-.3c-.756.997-1.89 1.75-3.403 2.285l.915 1.275c1.529-.615 2.852-1.465 3.782-2.679a7.574 7.574 0 0 0 .945-1.668v.693h2.222v3.498h1.639V7.429z"
      fill="#E73E11"
    />
    <path
      d="m28.564 21.792-.997-1.48.113-.024a6.932 6.932 0 0 0 2.897-1.358c.887-.7 1.374-1.517 1.374-2.304v-.724h2.014v.724c0 .795.482 1.613 1.358 2.303a6.913 6.913 0 0 0 2.913 1.358l.114.023-.996 1.48-.052-.012a8.512 8.512 0 0 1-2.717-1.24c-.768-.56-1.314-1.125-1.63-1.683-.328.562-.878 1.126-1.639 1.681a8.604 8.604 0 0 1-2.704 1.242l-.048.014zm4.4-3.268.067.135c.288.577.842 1.167 1.647 1.753.795.55 1.68.956 2.614 1.203l.804-1.198a7.088 7.088 0 0 1-2.867-1.364c-.912-.719-1.415-1.58-1.415-2.423v-.569H32.11v.569c0 .836-.51 1.696-1.432 2.424a7.1 7.1 0 0 1-2.85 1.358l.803 1.2a8.43 8.43 0 0 0 2.601-1.205c.796-.58 1.358-1.17 1.66-1.752l.072-.13zm4.85-2.305H36.02V7.114h1.793v9.105zm-1.64-.155h1.485V7.269h-1.484v8.795zm-9.826-.415-1.01-1.409.098-.034c1.422-.504 2.515-1.215 3.253-2.115l-2.58.29-.362-1.605 3.837-.239c.122-.355.21-.721.265-1.093h-3.551v-1.62h5.496V7.9a9.027 9.027 0 0 1-.336 2.49h1.789v-3.04h1.793v8.127h-1.793V11.98h-2.222v-.4c-.221.476-.491.927-.806 1.346-.872 1.139-2.12 2.023-3.814 2.704l-.057.02zm-.764-1.332.815 1.144c1.64-.668 2.848-1.53 3.693-2.632a7.367 7.367 0 0 0 .935-1.652l.153.035v.616h2.223v3.498h1.483v-7.82h-1.484v3.04H31.25l.03-.1a8.829 8.829 0 0 0 .362-2.468h-5.19v1.31h3.574l-.013.091c-.058.43-.16.851-.303 1.26l-.017.048-3.757.234.295 1.293 2.807-.311-.11.143c-.742.97-1.866 1.736-3.344 2.275v-.004z"
      fill="#E73E11"
    />
  </svg>
);
