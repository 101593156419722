import Link from "next/link";
import { useRouter } from "next/router";
import { rem } from "polished";
import { ComponentProps, VFC } from "react";
import { styled } from "~/stitches.config";

const BaseButton = styled("button", {
  width: rem(110),
  height: rem(44),
  border: "1px solid $primary",
  borderRadius: rem(100),
  backgroundColor: "$white",
  variants: {
    direction: {
      station: {
        color: "$gray01",
        borderColor: "$gray01",
        "&:hover": {
          borderColor: "$gray01",
          backgroundColor: "$black10",
        },
        "&:active, &.active": {
          borderColor: "$gray01",
          backgroundColor: "$gray01",
          color: "$white",
        },
      },
      waiting: {
        color: "$primary",
        borderColor: "$primary",
        "&:hover": {
          borderColor: "$primary",
          backgroundColor: "rgba(235, 84, 30, 0.1)",
        },
        "&:active, &.active": {
          borderColor: "$primary",
          backgroundColor: "$primary",
          color: "$white",
        },
      },
    },
  },
});

export const WaitingButton: VFC<ComponentProps<typeof BaseButton>> = ({
  ...props
}) => {
  const router = useRouter();
  const isStation = router.pathname.includes("station");

  return (
    <Link href="/diagnosis" passHref>
      <BaseButton
        className={!isStation ? "active" : ""}
        direction="waiting"
        {...props}
      >
        진료실
      </BaseButton>
    </Link>
  );
};

export const StationButton: VFC<ComponentProps<typeof BaseButton>> = ({
  ...props
}) => {
  const router = useRouter();
  const isStation = router.pathname.includes("station");

  return (
    <Link href="/diagnosis/station" passHref>
      <BaseButton
        className={isStation ? "active" : ""}
        direction="station"
        {...props}
      >
        스테이션
      </BaseButton>
    </Link>
  );
};
