import { rem } from "polished";
import { NoticeIcon } from "~/components";
import { styled } from "~/stitches.config";

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  fontSize: "$p3-15",
  ".doctor-status": {
    color: "$primary",
    marginLeft: rem(7),
  },
});

export const DoctorStatus = () => {
  return (
    <Flex
      css={{
        justifyContent: "space-between",
        margin: `${rem(19)} 0 ${rem(14)}`,
        position: "relative",
      }}
    >
      <Flex>
        <NoticeIcon />
        <div>
          <span className="doctor-status">진행 중인 진료</span>
          <span>가 있어요.</span>
        </div>
      </Flex>
    </Flex>
  );
};
