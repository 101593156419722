import { rem } from "polished";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dl,
  Dt,
  Dd,
  SettingsSection,
  SettingsSectionContent,
  SettingsSectionHeader,
  SettingsEditButton,
  OpeningTimesInfoForm,
  Heading, TutorialDialog2, RoundedButton
} from "~/components";
import { useGetOpeningTimesQuery } from "~/services/doctor";
import {
  selectOpeningHoursEditState,
  setOpeningHoursEditState
} from "~/store/settingsSlice";

const OpeningTimesInfo = () => {
  const { data } = useGetOpeningTimesQuery();


  const workingHours = data?.data?.map((day) => ({
    day: ["월요일", "화요일", "수요일", "목요일", "금요일", "토요일", "일요일"][
      day.weekday
    ],
    hours: [
      [day.startTime?.substring(0, 5), day.breakStTime?.substring(0, 5)],
      [day.breakEdTime?.substring(0, 5), day.endTime?.substring(0, 5)],
    ],
  }));

  return (
    <>
      <Dl
        css={{
          display: "grid",
          gridTemplateColumns: `${rem(66)} 1fr`,
          columnGap: rem(16),
          rowGap: rem(20),
          padding: rem(30),
        }}
      >
        {workingHours?.map((day) => (
          <Fragment key={day.day}>
            <Dt
              key={day.day}
              css={{
                gridColumn: "1/2",
                fontSize: rem(15),
                fontWeight: "bold",
              }}
            >
              {day.day}
            </Dt>
            <Dd
              css={{
                gridColumn: "2/3",
                "&:not(:last-of-type)": {
                  paddingBottom: rem(20),
                  borderBottom: "1px dashed $gray06",
                },
              }}
            >
              <Dl
                css={{
                  display: "grid",
                  gridTemplateColumns: `${rem(72)} 1fr`,
                  gridAutoRows: `${rem(24)}`,
                  columnGap: rem(20),
                  rowGap: rem(8),
                  alignItems: "center",
                }}
              >
                {day.hours?.map(([start, end], index) => (
                  <Fragment key={index}>
                    <Dt
                      css={{
                        gridColumn: "1/2",
                        fontSize: rem(15),
                        color: "$gray05",
                      }}
                    >
                      {index === 0 ? "오전 진료" : "오후 진료"}
                    </Dt>
                    <Dd css={{ fontSize: rem(17) }}>
                      {start} ~ {end}
                    </Dd>
                  </Fragment>
                ))}
              </Dl>
            </Dd>
          </Fragment>
        ))}
      </Dl>
    </>
  );
};

export const OpeningTimesInfoSection: FC = () => {
  const edit = useSelector(selectOpeningHoursEditState) === "edit";
  const [data, setData] = useState(false);
  const dispatch = useDispatch()
  const handleDialogOpen = () => {
    setData(() => !data)
  }

  return (
    <>
      <SettingsSection className="OpeningTimesInfoSection">
        <SettingsSectionHeader
          headerText={edit ? "운영 시간 수정" : "운영 시간"}
          css={{
            "h2:after": {
              content: '"*"',
              color: "$primary",
            },
          }}
        >
          {!edit && (
            <SettingsEditButton
              onClick={() => dispatch(setOpeningHoursEditState("edit"))}
            />
          )}
        </SettingsSectionHeader>
        <SettingsSectionContent>
          {edit ? <OpeningTimesInfoForm /> : <OpeningTimesInfo />}
          {/* <RoundedButton
            onClick={handleDialogOpen}
            css={{
              width: "90%",
              height: rem(50),
              boxShadow: "none",
              marginBottom: "2vh",
              marginTop: "1vh",
              fontWeight: "bold",
              fontSize: 14,
              marginLeft: "2.5rem"
            }}
            outlined
            color="orange"
          >
            <span>운영 시간 수정</span>
          </RoundedButton> */}
        </SettingsSectionContent>
      </SettingsSection>

    </>
  );
};
