import { rem } from "polished";
import { ComponentProps, FC, ReactElement } from "react";
import { Article, Header, Section } from "~/components";

export const SettingsSection: FC<ComponentProps<typeof Section>> = ({
  children,
  css,
  ...props
}) => {
  return (
    <Section
      css={{
        display: "flex",
        flexDirection: "column",
        ...css,
      }}
      {...props}
    >
      {children}
    </Section>
  );
};

export const SettingsSectionHeader: FC<
  ComponentProps<typeof Header> & { headerText: string }
> = ({ headerText, children, css, ...props }) => {
  return (
    <Header
      css={{
        margin: 0,
        padding: 0,
        height: rem(40),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: rem(6),
        h2: {
          margin: 0,
          padding: 0,
          fontSize: rem(15),
        },
        ...css,
      }}
      {...props}
    >
      <h2>{headerText}</h2>
      {children}
    </Header>
  );
};

export const SettingsSectionContent: FC<ComponentProps<typeof Article>> = ({
  children,
  css,
  ...props
}) => {
  return (
    <Article
      css={{
        backgroundColor: "$white",
        borderRadius: rem(20),
        ...css,
      }}
      {...props}
    >
      {children}
    </Article>
  );
};
