import { rem } from "polished";
import { FC } from "react";
import { Li, Ul } from "~/components";

export const TagList: FC<{ tags: string[]; vertical?: boolean }> = ({
  tags,
  vertical,
}) => {
  if (vertical) {
    return (
      <Ul css={{ display: "flex", flexDirection: "column", gap: rem(10) }}>
        {tags?.map((tag, index) => (
          <Li
            key={`${tag}-${index}`}
            css={{
              padding: `${rem(10)} ${rem(28)} ${rem(12)}`,
              fontSize: rem(14),
              backgroundColor: "$gray08",
              borderRadius: rem(100),
            }}
          >
            {tag}
          </Li>
        ))}
      </Ul>
    );
  }
  return (
    <Ul css={{ display: "flex", flexWrap: "wrap", gap: rem(10) }}>
      {tags?.map((tag, index) => (
        <Li
          key={`${tag}-${index}`}
          css={{
            padding: `${rem(10)} ${rem(28)} ${rem(12)}`,
            fontSize: rem(14),
            backgroundColor: "$gray08",
            borderRadius: rem(100),
          }}
        >
          {tag}
        </Li>
      ))}
    </Ul>
  );
};
