import { rem } from "polished";
import { useState } from "react";
import { Section, RoundedButton } from "~/components";

interface Props {
  filterByTimeRange: (time: string) => void;
}

const BUTTONS_VALUE = [
  { text: "전체", value: "all" },
  { text: "7일", value: "7" },
  { text: "30일", value: "30" },
  { text: "3개월", value: "90" },
  { text: "6개월", value: "180" },
];
export const FilterButtons: React.FC<Props> = ({ ...props }) => {
  return (
    <Section
      css={{
        marginTop: rem(16),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: rem(8),
      }}
    >
      {BUTTONS_VALUE.map((item, index) => (
        <RoundedButton
          key={index}
          color="white"
          css={{ flex: `${rem(100)} 1`, maxWidth: rem(145), height: rem(44) }}
          onClick={() => props.filterByTimeRange(item.value)}
        >
          {item.text}
        </RoundedButton>
      ))}
    </Section>
  );
};
