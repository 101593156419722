import { rem } from "polished";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SettingsEditButton,
  SettingsSection,
  SettingsSectionContent,
  SettingsSectionHeader,
  SettingsHospitalInfoForm,
  SettingsHospitalInfo,
} from "~/components";
import {
  selectHospitalEditState,
  setHospitalEditState,
} from "~/store/settingsSlice";

export const HospitalInfoSection: FC = () => {
  const dispatch = useDispatch();
  const hospitalEditState = useSelector(selectHospitalEditState);
  const edit = hospitalEditState !== "idle";

  return (
    <SettingsSection className="HospitalInfoSection">
      <SettingsSectionHeader headerText={edit ? "병원 정보 수정" : "병원 정보"}>
        {!edit && ( // 기본진료비
          <SettingsEditButton
            onClick={() => {
              dispatch(setHospitalEditState("edit"));
            }}
          />
        )}
      </SettingsSectionHeader>
      <SettingsSectionContent css={{ padding: `${rem(30)} ${rem(32)}` }}>
        {edit ? <SettingsHospitalInfoForm /> : <SettingsHospitalInfo />}
      </SettingsSectionContent>
    </SettingsSection>
  );
};
