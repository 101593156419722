import { Result } from "antd";
import { rem } from "polished";
import { Reducer, ReducerState, useEffect, useReducer, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PhoneNumberCertificationResult } from "~//interfaces";
import {
  Dropdown,
  RegisterFormRowItem,
  FormRowItemWrapper,
  RegisterFormSection,
  FileDropInput,
  Label,
  PhoneNumberCertificationButton,
  CheckedIcon,
  ProfilePic,
  PlaceHolder,
  LicenceField,
  Input,
  DropdownOnChange,
  FormFieldErrorMessage,
} from "~/components";
import { SPECIAL_OPTIONS } from "~/utils/constants";
import { validateImageFile } from "~/utils/validation.utils";
import { api } from "~/woozooapi";

const reducer: Reducer<
  {
    doctorLicenseFileName: string;
    specialLicenseFileName: string;
  },
  {
    type: string;
    payload: any;
  }
> = (state, action) => {
  switch (action.type) {
    case "setDoctorLicenceFileName":
      return { ...state, doctorLicenseFileName: action.payload };
    case "setSpecialLicenseFileName":
      return { ...state, specialLicenseFileName: action.payload };
    default:
      return state;
  }
};

const initialState: ReducerState<typeof reducer> = {
  doctorLicenseFileName: "",
  specialLicenseFileName: "",
};

export const DoctorInfoForm = () => {
  const [registerFormState, dispatch] = useReducer(reducer, initialState);
  const { register, setValue, setError, trigger, getValues, formState } =
    useFormContext();
  const [isPhoneVerifed, setIsPhoneVerifed] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>();
  const [doctorGetName, useDoctorGetName] = useState("");

  // const handleCertificationResult = async (
  //   result: PhoneNumberCertificationResult
  // ) => {
  //   if (!result.success) {
  //     // Phone number validation failed
  //     setIsPhoneVerifed(false);
  //     return;
  //   }

  //   // Phone number is verified
  //   // 1. update phone number
  //   // 2. phone number valid state to true
  //   setIsPhoneVerifed(true);
  //   setValue("certUser", result);
  //   setValue("phone", result.phone);
  //   setValue("name", result.name);
  //   await trigger(["phone", "name", "certUser"]);
  // };

  useEffect(() => {
    const profilePic = getValues("profilePic");
    if (typeof profilePic === "string" && profilePic.includes("aws")) {
      setProfilePicUrl(profilePic);
    }
  }, [getValues, formState]);

  const handleProfilePicUpload = async (file: File) => {
    const result = validateImageFile(file);

    if (!result.valid) {
      setError("profilePic", {
        types: result.message,
      });
      return;
    }

    const doctorName = getValues("name");

    // if (!doctorName) {
    //   // TODO: Maybe we should show this as field error.
    //   alert("휴대폰 인증 후 업로드 할 수 있습니다."); // "You can upload after phone certification."
    //   return;
    // }

    //   const res = await api.fileUpload({ //기존 파일업로드
    //     file,
    //     prefix: "profile",
    //     name: doctorName,
    //   });
    //   setValue("profilePic", res.url);
    //   await trigger("profilePic");
    // };

    const res = await api.fileUpload({
      file,
      prefix: "profile",
      name: doctorName,
    }).then((data) => { setValue("profilePic", data.url) }).catch(() => alert("이름을 입력해주세요,"))
    // setValue("profilePic", res.url);
    await trigger("profilePic");
  };
  const [textValue, setTextValue] = useState("");
  const handleProfilePicDelete = () => {
    setValue("profilePic", undefined);
    setProfilePicUrl(undefined);
  };

  const handleSpecialSelect: DropdownOnChange = async (special) => {
    setValue("special", special?.value);
    await trigger("special");
  };

  const handleDoctorLicenseUpload = async (file: File) => {
    const result = validateImageFile(file);

    if (!result.valid) {
      setError("doctorLicense", {
        types: result.message,
      });
      return;
    }

    dispatch({ type: "setDoctorLicenceFileName", payload: file.name });

    const response = await api.fileUpload({
      file,
      name: "doctor-license",
    });

    setValue("doctorLicense", response.url);
    await trigger("doctorLicense");
  };

  const handleSpecialLicenseUpload = async (file: File) => {
    const result = validateImageFile(file);

    if (!result.valid) {
      setError("specialLicense", {
        types: result.message,
      });
      return;
    }

    dispatch({ type: "setSpecialLicenseFileName", payload: file.name });

    const response = await api.fileUpload({
      file,
      name: "special-license",
    });

    setValue("specialLicense", response.url);
    await trigger("specialLicense");
  };

  const handleDoctorLicenseDelete = async () => {
    setValue("doctorLicense", undefined);
    await trigger("doctorLicense");
    dispatch({ type: "setDoctorLicenceFileName", payload: "" });
  };

  const handleSpecialLicenseDelete = async () => {
    setValue("specialLicense", undefined);
    await trigger("specialLicense");
    dispatch({ type: "setSpecialLicenseFileName", payload: "" });
  };

  const onlyNumberText = (data: string) => {
    setTextValue(data)
    if (data) {
      const replaceData = data.replace(/[^0-9]/g, "")
      return setTextValue(replaceData)
    }
  };

  return (
    <RegisterFormSection>
      <RegisterFormRowItem
        label="이름"
        placeholder="이름을 입력해 주세요."
        autoComplete="off"
        id="name"
        type="text"
        required
        {...register("name")}
      />
      <RegisterFormRowItem
        value={textValue}
        label="휴대폰 번호"
        placeholder="숫자만 입력해주세요."
        maxLength={11}
        type="tel"
        required
        {...register("phone", {
          onChange: (e) => {
            onlyNumberText(e.target.value)
          }
        })}
      >
      </RegisterFormRowItem>
      <RegisterFormRowItem
        id="email"
        type="email"
        label="이메일(아이디)"
        autoComplete="off"
        placeholder="이메일을 입력해 주세요."
        required
        {...register("email")}
      />
      <RegisterFormRowItem
        id="pw1"
        type="password"
        label="비밀번호"
        autoComplete="new-password"
        placeholder="비밀번호를 입력해 주세요."
        required
        {...register("pw1")}
      />
      <RegisterFormRowItem
        id="pw2"
        type="password"
        label="비밀번호 확인"
        autoComplete="off"
        placeholder="비밀번호를 한번 더 입력해 주세요."
        required
        {...register("pw2")}
      />
      <FormRowItemWrapper>
        <Label htmlFor="profilePic" required>
          프로필 사진
        </Label>
        <Input type="hidden" {...register("profilePic")} />
        {profilePicUrl ? (
          <ProfilePic
            deletable
            src={profilePicUrl}
            onDeleteClick={handleProfilePicDelete}
          />
        ) : (
          <>
            <FileDropInput
              accept="image/jpeg, image/png"
              handleFile={handleProfilePicUpload}
            />
            {formState.errors.profilePic?.message ? (
              <FormFieldErrorMessage
                css={{ gridArea: "message", marginTop: rem(6) }}
              >
                {formState.errors.profilePic?.message}
              </FormFieldErrorMessage>
            ) : (
              <PlaceHolder css={{ gridArea: "message" }}>
                고객에게 보여질 프로필 사진을 등록해주세요.
              </PlaceHolder>
            )}
          </>
        )}
      </FormRowItemWrapper>
      <LicenceField
        required
        name="doctorLicense"
        label="의사 면허증"
        fileName={registerFormState.doctorLicenseFileName}
        handleUpload={handleDoctorLicenseUpload}
        handleDelete={handleDoctorLicenseDelete}
      />
      <FormRowItemWrapper>
        <Label htmlFor="specialSelect">전문의 선택</Label>
        <Input type="hidden" {...register("special")} />
        <Dropdown
          aria-label="전문의 선택"
          id="specialSelect"
          placeholder="전문의를 선택해주세요."
          options={SPECIAL_OPTIONS}
          onChange={handleSpecialSelect}
        />
        {formState.errors.special?.message && (
          <FormFieldErrorMessage
            css={{ gridArea: "message", marginTop: rem(6) }}
          >
            {formState.errors.special?.message}
          </FormFieldErrorMessage>
        )}
      </FormRowItemWrapper>
      <LicenceField
        name="specialLicense"
        label="전문의 자격증"
        fileName={registerFormState.specialLicenseFileName}
        handleUpload={handleSpecialLicenseUpload}
        handleDelete={handleSpecialLicenseDelete}
      />
    </RegisterFormSection>
  );
};
