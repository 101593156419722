import { rem } from "polished";
import React, { useEffect } from "react";
import {
  StatusBadge,
  WaitingRoomBtn,
  NewIcon,
  PrivateIcon_XSmall,
} from "~/components";
import { BadgeStatus, WaitingCardProps } from "~/interfaces";
import { styled } from "~/stitches.config";
import { toDashedPhoneNumber } from "~/utils/phone.utils";

export const Card = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: rem(24),
  paddingLeft: rem(15),
  width: "100%",
  height: rem(124),
  borderRadius: rem(20),
  position: "relative",
  margin: `${rem(12)} 0`,
  backgroundColor: "white",
  lineHeight: "1.4",
  fontSize: "$p5-13",
  fontWeight: "normal",
  fontStyle: "normal",
  letteSpacing: "normal",
  border: "1px solid $white",
  "&:hover": {
    border: "1px solid $primary",
  },
  ".patient-info": {
    display: "flex",
    flexDirection: "column",
  },
  ".new-icon": {
    position: "absolute",
    top: rem(-4),
    left: rem(-9),
  },
  ".name": {
    fontSize: "$s4-19",
    fontWeight: "bold",
  },
  ".gender": { marginLeft: rem(8) },
  ".phone-number": {
    margin: `${rem(8)} 0`,
  },
  ".application-time": {
    color: "$black40",
  },
});

export const WaitingListCard = (props: WaitingCardProps<BadgeStatus>) => {
  return (
    <>
      <Card>
        {props.status == "waiting" && (
          <div className="new-icon">
            <NewIcon />
          </div>
        )}
        <div>
          <StatusBadge {...props} />
        </div>
        <div className="patient-info">
          <div style={{ display: "flex", alignItems: "center", gap: rem(8) }}>
            <div>
              <span style={{ marginRight: 8 }} className="name">{props.username}</span>
              {props.gender ||
                (props.age && (
                  <span className="gender">
                    ({props.gender} / {props.age})
                  </span>
                ))}
            </div>

            {props.private === true && <PrivateIcon_XSmall />}
            {console.log("props.private", props.private)}
          </div>
          <p className="phone-number">{toDashedPhoneNumber(props.mobile)}</p>
          <p className="application-time">신청시간: {props.created}</p>
          <WaitingRoomBtn status={props.status} id={props.id} />
        </div>
      </Card>
    </>
  );
};
