import { rem } from "polished";
import { FC } from "react";
import { Div } from "~/components";

export const SpeciallistBadge: FC = ({ children }) => (
  <Div
    role="note"
    css={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: rem(34),
      color: "$primary",
      backgroundColor: "$fileInputBgColor",
      padding: `0 ${rem(8)}`,
      borderRadius: rem(6),
    }}
  >
    {children} 전문의
  </Div>
);