import { NextRouter, useRouter } from "next/router";
import { rem } from "polished";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
} from "react";
import { Address } from "react-daum-postcode";
import { useDispatch, useSelector } from "react-redux";
import {
  Article,
  AutocompleteHospitalSearch,
  BackOnDialogIcon,
  DaumAddressSearch,
  Div,
  Header,
  Heading,
  IconButton,
  Input,
  Label,
  P,
  RegisterFormRowItem,
  FormRowItemWrapper,
  RoundedButton,
} from "~/components";
import { Hospital } from "~/interfaces";
import { useGetDoctorInfoQuery } from "~/services/doctor";
import {
  setHospitalEditState,
  setHospitalDataForUpdate,
  selectHospitalDataForUpdate,
} from "~/store/settingsSlice";

export const Init = ({ router }: { router: NextRouter }) => (
  <>
    <Header css={{ paddingBlock: rem(30) }}>
      <Heading
        as="h2"
        css={{
          fontSize: rem(17),
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: 1,
        }}
      >
        병원 등록
      </Heading>
    </Header>
    <Article
      css={{
        paddingInline: rem(30),
        paddingBlockEnd: rem(30),
      }}
    >
      <Input
        usage="registerPage"
        placeholder="병원 이름, 전화번호로 검색"
        css={{
          borderColor: "$gray01",
          boxShadow: `0 ${rem(3)} ${rem(3)} 0 rgba(140, 140, 140, 0.3);`,
        }}
        onFocus={() =>
          router.push("/settings/edit/hospital/search", undefined, {
            scroll: false,
          })
        }
      />
      <P css={{ textAlign: "center", paddingBlock: rem(30) }}>또는</P>
      <RoundedButton
        color="orange"
        outlined
        css={{ height: rem(50), width: "100%" }}
        onClick={(e) => {
          e.preventDefault();
          router.push("/settings/edit/hospital/custom", undefined, {
            scroll: false,
          });
        }}
      >
        직접 입력
      </RoundedButton>
    </Article>
  </>
);

export const Search = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: doctorInfo } = useGetDoctorInfoQuery();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleHospitalSearchResult = (hospitalData: Hospital) => {
    dispatch(setHospitalEditState("update"));
    dispatch(setHospitalDataForUpdate(hospitalData));
    router.push("/settings", undefined, { scroll: false });
  };

  const handleCustomHospitalClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    dispatch(setHospitalEditState("update"));
    router.push("/settings/edit/hospital/custom", undefined, {
      scroll: false,
    });
  };

  return (
    <>
      <Header css={{ paddingBlock: rem(30) }}>
        <Heading
          as="h2"
          css={{
            fontSize: rem(17),
            fontWeight: "bold",
            textAlign: "center",
            lineHeight: 1,
          }}
        >
          병원 등록
        </Heading>
      </Header>
      <Article css={{ paddingInline: rem(30) }}>
        <AutocompleteHospitalSearch
          hospitalUserId={doctorInfo?.hospitalUser.id}
          handleSelect={handleHospitalSearchResult}
        />
      </Article>
      <Div
        className="full"
        css={{
          position: "absolute",
          left: 0,
          bottom: 0,
          height: rem(90),
          borderTop: "1px solid $gray01",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: `${rem(20)} ${rem(30)}`,
          backgroundColor: "$white",
        }}
      >
        찾으시는 병원이 없으신가요?{" "}
        <RoundedButton
          color="orange"
          outlined
          css={{
            "@bp1": {
              width: rem(180),
            },
            width: rem(120),
            height: rem(50),
            boxShadow: "0 3px 3px 0 rgba(140, 140, 140, 0.3)",
          }}
          onClick={handleCustomHospitalClick}
        >
          직접 입력
        </RoundedButton>
      </Div>
    </>
  );
};

export const Custom = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const hospitalNextData = useSelector(selectHospitalDataForUpdate);

  const handleAddressSearchClick = () => {
    router.push("/settings/edit/hospital/address", undefined, {
      scroll: false,
    });
  };

  const handleHospitalName: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(
      setHospitalDataForUpdate({
        name: e.target.value,
      })
    );
  };

  const handleHospitalPhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(
      setHospitalDataForUpdate({
        phone: e.target.value,
      })
    );
  };

  const handleHospitalAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(
      setHospitalDataForUpdate({
        address2: e.target.value,
      })
    );
  };

  const handleCustomHospitalInfoSubmit = () => {
    dispatch(
      setHospitalDataForUpdate({
        id: undefined,
      })
    );
    router.push("/settings", undefined, { scroll: false });
  };

  const showAddressFields = !!useSelector(selectHospitalDataForUpdate).address1;

  return (
    <>
      <Header css={{ paddingBlock: rem(30) }}>
        <IconButton
          css={{ position: "absolute", top: rem(22), left: rem(22) }}
          onClick={() => router.back()}
        >
          <BackOnDialogIcon />
        </IconButton>
        <Heading
          as="h2"
          css={{
            fontSize: rem(17),
            fontWeight: "bold",
            textAlign: "center",
            lineHeight: 1,
          }}
        >
          직접 입력
        </Heading>
      </Header>
      <Article
        css={{
          display: "flex",
          flexDirection: "column",
          paddingInline: rem(30),
          paddingBlockEnd: rem(30),
          gap: rem(20),
          [`& ${FormRowItemWrapper}`]: {
            gridTemplateColumns: `${rem(96)} 1fr`,
          },
        }}
      >
        <RegisterFormRowItem
          label="병원 이름"
          id="hospitalName"
          placeholder="병원 이름을 입력해주세요."
          onChange={handleHospitalName}
          value={hospitalNextData.name}
        />
        <RegisterFormRowItem
          label="전화번호"
          id="hospitalPhone"
          placeholder="전화번호를 입력해주세요."
          onChange={handleHospitalPhone}
          value={hospitalNextData.phone}
        />
        <FormRowItemWrapper css={{ alignItems: "initial" }}>
          <Label>주소</Label>
          <Div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: rem(10),
              input: {
                width: "100%",
              },
            }}
          >
            <RoundedButton
              outlined
              css={{
                height: rem(50),
                color: "$black",
                backgroundColor: "$white",
                boxShadow: "0 3px 3px 0 rgba(140, 140, 140, 0.3)",
                fontWeight: "bold",
              }}
              onClick={handleAddressSearchClick}
            >
              주소 검색
            </RoundedButton>
            <Input
              id="hospitalAddress1Dialog"
              disabled
              hidden={!showAddressFields}
              css={{
                alignSelf: "flex-end",
                border: "1px solid $gray06",
                backgroundColor: "$gray08",
              }}
              value={hospitalNextData.address1}
            />
            <Input
              id="hospitalAddress2Dialog"
              hidden={!showAddressFields}
              css={{
                alignSelf: "flex-end",
                border: "1px solid $gray06",
                backgroundColor: "$gray08",
              }}
              onChange={handleHospitalAddress2}
            />
          </Div>
        </FormRowItemWrapper>
        <RoundedButton
          color="orange"
          type="submit"
          css={{ marginTop: rem(20), height: rem(50) }}
          onClick={handleCustomHospitalInfoSubmit}
        >
          저장
        </RoundedButton>
      </Article>
    </>
  );
};

export const AddressSeach = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <>
      <Header css={{ paddingBlock: rem(30) }}>
        <Heading
          as="h2"
          css={{
            fontSize: rem(17),
            fontWeight: "bold",
            textAlign: "center",
            lineHeight: 1,
          }}
        >
          <IconButton
            css={{ position: "absolute", top: rem(22), left: rem(22) }}
            onClick={() => router.back()}
          >
            <BackOnDialogIcon />
          </IconButton>
          주소 검색
        </Heading>
      </Header>
      <Article>
        <DaumAddressSearch
          style={{ height: `var(--sizes-dialogHeight)` }}
          onComplete={(addressData: Address) => {
            dispatch(
              setHospitalDataForUpdate({
                postcode: addressData.zonecode,
                state: addressData.sido,
                city: addressData.sido,
                address1: addressData.address,
              })
            );
            router.push("/settings/edit/hospital/custom", undefined, {
              scroll: false,
            });
          }}
        />
      </Article>
    </>
  );
};
