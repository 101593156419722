import { rem } from "polished";
import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dd, Dl, Dt, TagList, SettingsSectionDlGrid } from "~/components";
import { DoctorInfoResponse } from "~/interfaces";
import { selectDiagnosisEditState, selectTutorialPriceDataUpdate, setTutorialPriceDataUpdate } from "~/store/settingsSlice";
import { api } from "~/woozooapi"

export const SettingsDiagnosisInfo = ({
  data,
}: {
  data?: DoctorInfoResponse;
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    api.doctor.info().then((res) => { dispatch(setTutorialPriceDataUpdate(res.hospitalUser.estimatedPayment)) })
  }, [])

  const priceData = useSelector(selectTutorialPriceDataUpdate);

  if (!data) return null;

  return (
    <SettingsSectionDlGrid section="diagnosisIdle">
      <Dt>진료 소개</Dt>
      <Dd
        css={{
          whiteSpace: "pre-line",
        }}
      >
        {data?.hospitalUser.description}
      </Dd>
      <Dt
        css={{
          "&:after": {
            content: "*",
            color: "$primary",
          },
        }}
      >
        비급여 진료비
      </Dt>
      <Dd>
        <Dl
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            dt: { gridColumn: "1/2" },
            dd: {
              gridColumn: "2/3",
              textAlign: "right",
            },
            rowGap: rem(8),
          }}
        >
          <Dt
            css={{
              gridColumn: "1/3",
              fontWeight: "bold",
              placeSelf: "center left",
            }}
          >
            기본 진료비
          </Dt>
          <Dd
            css={{
              gridColumn: "1/3",
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
              fontSize: rem(14),
              alignItems: "center",
              data: {
                fontSize: rem(15),
                color: "$primary",
                fontWeight: "bold",
                marginRight: rem(4),
              },
            }}
          >
            {
              <>
                <data value={data?.hospitalUser?.estimatedPayment}>
                  {data?.hospitalUser?.estimatedPayment?.toLocaleString()}
                </data>{" "}
                원
              </>
            }
          </Dd>
          {data?.estimatedItem?.map((item, index) => (
            <Fragment key={index}>
              <Dt css={{ fontSize: rem(14) }}>{item.name}</Dt>
              <Dd
                key={item.id}
                css={{
                  fontSize: rem(14),
                  data: {
                    color: "$primary",
                  },
                }}
              >
                {item.isVariable ? (
                  <data>처방 기간에 따라 변동</data>
                ) : (
                  <>
                    <data value={item.price}>
                      {item.price?.toLocaleString()}
                    </data>
                    원
                  </>
                )}
              </Dd>
            </Fragment>
          ))}
        </Dl>
      </Dd>
      <Dt
        css={{
          "&:after": {
            content: "*",
            color: "$primary",
          },
        }}
      >
        진료 과목
      </Dt>
      <Dd>
        {data?.treatmentSubject && (
          <TagList
            tags={data?.treatmentSubject?.map((subject) => subject.name)}
          />
        )}
      </Dd>
      {data?.reimbursementItem && (
        <>
          <Dt>급여 진료 과목</Dt>
          <Dd>
            {data?.reimbursementItem && (
              <TagList
                tags={data?.reimbursementItem?.map((subject) => subject.name)}
              />
            )}
          </Dd>
        </>
      )}
    </SettingsSectionDlGrid>
  );
};
