export const CopyrightIcon = () => (
  <svg
    width="90"
    height="24"
    viewBox="0 0 90 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity=".2">
      <path
        d="M13.068 15.143c.715 0 1.397-.275 1.925-.759l-.43-.671c-.373.341-.857.594-1.407.594-1.1 0-1.848-.913-1.848-2.288 0-1.375.792-2.31 1.87-2.31.484 0 .847.22 1.199.539l.517-.671c-.43-.374-.968-.704-1.75-.704-1.528 0-2.881 1.155-2.881 3.146 0 1.98 1.22 3.124 2.805 3.124z"
        fill="#000"
      />
      <circle cx="13" cy="12" r="5.5" stroke="#000" />
      <path
        d="M26.24 12.42v.92c0 1.12-.55 1.74-1.62 1.74S23 14.46 23 13.34v-3.6C23 8.63 23.55 8 24.62 8s1.62.63 1.62 1.74v.68h-1v-.74c0-.51-.22-.69-.56-.69-.34 0-.57.18-.57.69v3.73c0 .51.22.68.57.68s.56-.17.56-.68v-1l1 .01zM26.81 9.74a1.66 1.66 0 1 1 3.31 0v3.6a1.661 1.661 0 0 1-2.874 1.256 1.66 1.66 0 0 1-.436-1.256v-3.6zm1.09 3.67c0 .51.22.69.56.69.34 0 .57-.18.57-.69V9.68c0-.51-.22-.69-.57-.69s-.56.18-.56.69v3.73zM33.09 15v-.12a1.069 1.069 0 0 1 0-.38V13.07c0-.65-.2-.88-.71-.88h-.37V15h-1.09V8.08h1.64c1.14 0 1.61.53 1.61 1.6v.54a1.39 1.39 0 0 1-.71 1.4c.55.23.72.75.72 1.48v1.06a2 2 0 0 0 .12.84h-1.21zm-1.18-5.93v2.13h.42c.41 0 .65-.18.65-.73v-.69c0-.5-.16-.71-.55-.71h-.52zM37.07 15v-.12a1.069 1.069 0 0 1 0-.38V13.07c0-.65-.21-.88-.71-.88h-.38V15h-1.09V8.08h1.51c1.14 0 1.62.53 1.62 1.6v.54a1.38 1.38 0 0 1-.72 1.4c.56.23.73.75.73 1.48v1.06c-.018.285.02.57.11.84h-1.07zm-1.19-5.93v2.13h.43c.4 0 .65-.18.65-.73v-.69c0-.5-.17-.71-.56-.71h-.52zM39.86 11h1.54v1h-1.49v2h1.87v1h-3V8.08h3v1h-1.87L39.86 11zM45.56 12.42v.92c0 1.12-.55 1.74-1.62 1.74s-1.62-.62-1.62-1.74v-3.6c0-1.11.55-1.74 1.62-1.74s1.62.63 1.62 1.74v.68h-1v-.74c0-.51-.21-.69-.56-.69-.35 0-.56.18-.56.69v3.73c0 .51.21.68.56.68.35 0 .56-.17.56-.68v-1l1 .01zM45.91 8.08h3.36v1h-1.14v5.93h-1.09V9.08h-1.13v-1zM49.74 8.08h1.09V15h-1.09V8.08zM51.53 9.74a1.658 1.658 0 0 1 1.655-1.789A1.66 1.66 0 0 1 54.84 9.74v3.6a1.661 1.661 0 0 1-2.873 1.256 1.66 1.66 0 0 1-.437-1.256v-3.6zm1.09 3.67c0 .51.22.69.56.69.34 0 .57-.18.57-.69V9.68c0-.51-.22-.69-.57-.69s-.56.18-.56.69v3.73zM56.51 9.99v5h-1V8.07h1.36l1.12 4.15V8.07h1v6.92h-1.12l-1.36-5zM64.49 13.73l.83-5.65h1L65.25 15h-1.62l-1.07-6.92h1.1l.83 5.65zM66.82 8.08h1.09V15h-1.09V8.08zM68.4 8.08h3.36v1h-1.13v5.93h-1.09V9.08H68.4v-1zM75.4 15h-1.09l-.19-1.25h-1.34L72.6 15h-1l1.1-6.92h1.6L75.4 15zm-2.52-2.15h1l-.52-3.5-.48 3.5zM75.871 8.08h1.09v5.94h1.79v1h-2.88V8.08zM80.31 11h1.49v1h-1.49v2h1.88v1h-3V8.08h3v1h-1.88V11z"
        fill="#000"
      />
    </g>
  </svg>
);
